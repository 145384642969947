import { AppInstallationordersModalStatusApproval, AppInstallationordersSlideover } from '#components'
import { z, type InstallationorderWithRelations } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppInstallationordersSlideoverProps = ComponentProps<typeof AppInstallationordersSlideover>

export type InstallationordersUtils = ReturnType<typeof useInstallationordersUtils>

export const useInstallationorders = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Installationorder>('installationorders')

  const slideoverOpenCreate = (props: AppInstallationordersSlideoverProps) => {
    return new Promise<Installationorderorder>((resolve, reject) => {
      const data = getDefaultInstallationorder(props.data)
      slideover.reset()
      slideover.open(AppInstallationordersSlideover, {
        title: 'Registrar orden de instalación',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
            slideover.reset()
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
      })
    })
  }

  const slideoverOpenEdit = (props: AppInstallationordersSlideoverProps) => {
    return new Promise<Installationorderorder>((resolve, reject) => {
      const data = getDefaultInstallationorder(props.data)
      slideover.reset()
      slideover.open(AppInstallationordersSlideover, {
        title: 'Editar orden de instalación',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
            slideover.reset()
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const modalOpenStatusApproval = (props: ComponentProps<typeof AppInstallationordersModalStatusApproval>) => {
    return new Promise<Installationorder>((resolve, reject) => {
      modal.open(AppInstallationordersModalStatusApproval, {
        data: props.data,
        onSubmit: async (event) => {
          try {
            const { id, data } = props.data
            const result = await <Installationorder>$fetch(`/api/portals/installationorders/${id}`, {
              method: 'PATCH',
              body: { ...data, status: 'completed', approval: event.data },
            }) // use custom endpoint for unauthenticated PATCH
            modal.close()
            useToastAlert().success('Aprobación finalizada con éxito.')
            resolve(result)
          }
          catch (error) {
            modal.close()
            useToastAlert().error('Error al finalizar la aprobación.')
            reject(error)
          }
        },
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    status: z.string(),
    priority: z.string(),
    uid: z.string(),
    name: z.string(),
    isDatetime: z.boolean(),
    surfaces: z.array(z.string()).nonempty('Es necesario seleccionar al menos una superficie'),
    removal: z.string(),
    openHours: z.string(),
    maxHeight: z.number().positive(),
    items: z.array(z.object({
      text: z.string(),
      images: z.array(z.string()).nonempty('Es necesario al menos un item y una imagen'),
    })).nonempty('Es necesario al menos un item y una imagen'),
    address: addressSchema,
    accountId: z.string(),
    contactId: z.string(),
    contactIdApproval: z.string(),
    dealId: z.string(),
    salesorderId: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Installationorder, refresh: () => Promise<void>, utils: InstallationordersUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'installationorders', row, refresh, utils),
      {
        label: 'Portal',
        icon: 'i-mdi-desktop-classic',
        variant: 'outline',
        to: `/portals/installationorders/${row.id}`,
        target: '_blank',
      },
      getDropdownItem('filePdf', 'installationorders', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'installationorders', row, refresh, utils),
      getDropdownItem('copyUid', 'installationorders', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'installationorders', row, refresh, utils),
      getDropdownItem('files', 'installationorders', row, refresh, utils),
    ],
    [
      getDropdownItem('info', 'installationorders', row, refresh, utils),
      getDropdownItem('immutable', 'installationorders', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'installationorders', row, refresh, utils),
      getDropdownItem('delete', 'installationorders', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  // files:

  const createPdf = async (data: InstallationorderWithRelations, utils: InstallationordersUtils) => {
    const [salesorder] = await $fetch<SalesorderWithRelations[]>(`/api/db/salesorders`, {
      query: { id: data.salesorderId, $with: relationsSalesorders },
    })
    const documentDefinition = await createPdfmakeDocumentDefinitionInstallationorders(data, utils, salesorder!)
    const pdf = await usePDFMake().createPdf(documentDefinition)
    return pdf
  }

  // updates the installationorder.files array (to be used after POST/PATCH)
  async function createFilePdf(data: Partial<Installationorder>, utils: InstallationordersUtils) {
    const { uploadFile, removeFile } = useApiBlob('installationorders', data.id)
    const [existingData] = await $fetch<InstallationorderWithRelations[]>(`/api/db/installationorders`, {
      query: { id: data.id, $with: relationsInstallationorders },
    })
    const newData = { ...existingData, ...data } as InstallationorderWithRelations
    const pdf = await createPdf(newData, utils)
    // IMPORTANT: wrap with a Promise to await the blob
    const blob: Blob = await new Promise(resolve => pdf.getBlob(blob => resolve(blob)))
    const file = new File([blob], 'installationorder.pdf', { type: 'application/pdf' })
    return uploadFile('filePdf', file)
  }

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    slideoverOpenCreate,
    slideoverOpenEdit,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    modalOpenStatusApproval,
    schema,
    getDropdownItems,
    createPdf,
    createFilePdf,
  }
}

export const useInstallationordersUtils = ({ $accounts, $branches, $contacts, $deals, $productionorders, $salesorders, $users }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $branches?: Awaited<ReturnType<typeof useFetch<Branch[]>>>
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $deals?: Awaited<ReturnType<typeof useFetch<Deal[]>>>
  $productionorders?: Awaited<ReturnType<typeof useFetch<Productionorder[]>>>
  $salesorders?: Awaited<ReturnType<typeof useFetch<Salesorder[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsInstallationorders,
    delivery: optionsDeliveries,
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    branchId: computed(() => $branches?.data.value.map(toOption)),
    contactId: computed(() => $contacts?.data.value.map(toOption)),
    contactIdApproval: computed(() => $contacts?.data.value.map(toOption)),
    dealId: computed(() => $deals?.data.value.map(toOption)),
    productionorderId: computed(() => $productionorders?.data.value.map(toOption)),
    salesorderId: computed(() => $salesorders?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsInstallationorders.type, 'value'),
    status: toMapByKey(optionsInstallationorders.status, 'value'),
    priority: toMapByKey(optionsInstallationorders.priority, 'value'),
    productionorder: {
      status: toMapByKey(optionsProductionorders.status, 'value'),
    },
    surfaces: toMapByKey(optionsInstallationorders.surfaces, 'value'),
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
    branchId: computed(() => toMapByKey($branches?.data.value, 'id')),
    contactId: computed(() => toMapByKey($contacts?.data.value, 'id')),
    contactIdApproval: computed(() => toMapByKey($contacts?.data.value, 'id')),
    dealId: computed(() => toMapByKey($deals?.data.value, 'id')),
    productionorderId: computed(() => toMapByKey($productionorders?.data.value, 'id')),
    salesorderId: computed(() => toMapByKey($salesorders?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
    branchesByAccountId: computed(() => toMapByGroupKey($branches?.data.value, 'accountId')),
    contactsByAccountId: computed(() => toMapByGroupKey($contacts?.data.value, 'accountId')),
    dealsByAccountId: computed(() => toMapByGroupKey($deals?.data.value, 'accountId')),
    salesorderByDealId: computed(() => toMapByGroupKey($salesorders?.data.value, 'dealId')),
    productionordersByType: computed(() => toMapByGroupKey($productionorders?.data.value, 'type')),
    productionordersBySalesorderId: computed(() => toMapByGroupKey($productionorders?.data.value, 'salesorderId')),
  }

  return { options, maps }
}
