import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId, createUid } from '#netzo/utils/core/db'
import type { CalendarEvent } from '@schedule-x/calendar'
import { addMonths, format } from 'date-fns'
import { merge } from 'es-toolkit/compat'

export const getDefaultCampaign = (data: Partial<Campaign>) => {
  const uid = createUid(new Date(), 'CAM') // IMPORTANT: cloudflare throws error if called on global scope
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'lead-generation',
    uid: uid,
    dateStart: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    dateEnd: new Date(`${format(addMonths(new Date(), 1), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    metrics: {
      probability: 0,
      value: 0,
      frequency: 'one-time',
    },
    tags: [],
    data: getDefaultCampaignData(data?.data ?? {}),
  }, data)
}

export const getCampaignInformation = (data: Partial<Campaign>) => {
  const maps = {
    type: toMapByKey(optionsCampaigns.type, 'value'),
  }
  if (!data) return []
  return [
    ['Referencia', data.uid],
    ['Nombre', data.name],
    ['Tipo', maps.type.get(data.type!)?.label],
    ['Descripción', data.description],
  ].map(([key, value]) => [key, value || '-'])
}

export const optionsCampaigns = {
  type: [
    { value: 'lead-generation', label: 'Generación leads', description: 'Campañas enfocadas en atraer nuevos prospectos y convertirlos en leads.', icon: 'i-mdi-lightbulb', color: 'teal' },
    { value: 'lead-nurturing', label: 'Nutrición leads', description: 'Campañas diseñadas para construir relaciones y avanzar prospectos en el embudo de ventas.', icon: 'i-mdi-handshake', color: 'green' },
    { value: 'sales-enablement', label: 'Habilitación ventas', description: 'Campañas dirigidas a impulsar el cierre de negocios mediante contenido orientado a la venta.', icon: 'i-mdi-sale', color: 'blue' },
    { value: 'upselling', label: 'Venta adicional', description: 'Campañas para incrementar ingresos ofreciendo productos o servicios complementarios.', icon: 'i-mdi-arrow-expand-all', color: 'purple' },
    { value: 'customer-retention', label: 'Retención clientes', description: 'Campañas para mantener a los clientes comprometidos y prevenir la pérdida de negocios.', icon: 'i-mdi-account-heart', color: 'red' },
    { value: 'event-based', label: 'Basadas en eventos', description: 'Campañas aprovechando eventos específicos como lanzamientos de productos o temporadas.', icon: 'i-mdi-calendar-star', color: 'orange' },
    { value: 'abm', label: 'Cuentas clave', description: 'Campañas personalizadas para cuentas clave de alto valor.', icon: 'i-mdi-account-star', color: 'gold' },
    { value: 'referral', label: 'Referidos', description: 'Campañas que fomentan que los clientes actuales refieran nuevos prospectos.', icon: 'i-mdi-account-multiple-plus', color: 'yellow' },
    { value: 'reengagement', label: 'Reactivación', description: 'Campañas para revivir el interés en prospectos o clientes inactivos.', icon: 'i-mdi-account-clock', color: 'cyan' },
    { value: 'other', label: 'Otros', description: 'Campañas que no encajan en las categorías anteriores.', icon: 'i-mdi-help', color: 'gray' },
  ],
  metrics: {
    frequency: optionsDeals.metrics.frequency,
  },
} satisfies Options

export const optionsCampaignsMap = {
  Tipo: optionsCampaigns.type,
  Métricas: {
    'Frecuencia de Negocio': optionsCampaigns.metrics.frequency,
  },
}

export const relationsCampaigns: Record<string, boolean> = {
  deals: true,
  user: true,
  files: true,
  // options: true,
}

export type CampaignWithRelations = Campaign & {
  deals: Deal[]
  user: User
  files: File[]
  options: Option[]
}

export const campaignToViewCalendarEvent = (calendarId: keyof Campaign, utils: CampaignsUtils) => {
  return (row: Campaign): CalendarEvent => {
    const calendarName = utils.maps[calendarId].get(row[calendarId])?.label?.toUpperCase()
    const dateFormat = row.isDatetime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
    return {
      ...row,
      id: row.id,
      title: `[${calendarName}] ${row.name}`,
      description: row.description!,
      start: format(new Date(row.dateStart), dateFormat),
      end: format(new Date(row.dateEnd), dateFormat),
      calendarId: row[calendarId],
    }
  }
}

export const viewSettingsCampaigns: ViewSettings = {
  tableName: 'campaigns',
  typeOptions: optionsShared.views.filter(view => ['grid', 'cards', 'calendar'].includes(view.value)),
  type: 'cards',
  pagination: { page: 1, pageSize: 50 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
      rowClass: 'text-xs', // for <td>
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateStart',
      label: 'Fecha de Inicio',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateEnd',
      label: 'Fecha de Cierre',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    // {
    //   key: 'image',
    //   label: 'Imágen',
    //   class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    // },
    {
      key: 'user.name',
      label: 'Responsable',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'deals',
      label: 'Negocios',
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groupBy: 'type',
  groupByOptions: [
    { label: 'Tipo', value: 'type', options: optionsActivities.type },
  ],
}
