<script setup lang="ts">
const props = defineProps<{
  icon?: string
  label: string
  isDropdown?: boolean
}>()
</script>

<template>
  <div class="grid grid-cols-[2fr,3fr] gap-x-2 items-start truncate">
    <div
      :title="label"
      class="flex items-center gap-0.5"
      :class="'!text-2xs !font-regular text-gray-400 dark:text-gray-600 -mb-0.5'"
      v-bind="$attrs"
    >
      <UIcon
        v-if="props.icon"
        :name="props.icon"
        class="w-4 h-4"
      />
      {{ label }}
    </div>
    <div class="flex justify-end truncate">
      <slot />
    </div>
  </div>
</template>
