import { AppIssuesSlideover } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppIssuesSlideoverProps = ComponentProps<typeof AppIssuesSlideover>

export type IssuesUtils = ReturnType<typeof useIssuesUtils>

export const useIssues = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Issue>('issues')

  const slideoverOpenCreate = (props: AppIssuesSlideoverProps) => {
    return new Promise<Issue>((resolve, reject) => {
      const { user } = useUserSession()
      const data = getDefaultIssue({userId: user.value.id, ...props?.data})
      slideover.reset()
      slideover.open(AppIssuesSlideover, {
        title: 'Reportar problema',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
            slideover.reset()
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
      })
    })
  }

  const slideoverOpenEdit = (props: AppIssuesSlideoverProps) => {
    return new Promise<Issue>((resolve, reject) => {
      const data = getDefaultIssue(props.data)
      slideover.reset()
      slideover.open(AppIssuesSlideover, {
        title: 'Editar reporte',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
            slideover.reset()
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    status: z.string(),
    priority: z.string(),
    name: z.string(),
    description: z.string(),
    userId: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Issue, refresh: () => Promise<void>, utils: IssuesUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'issues', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'issues', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'issues', row, refresh, utils),
      getDropdownItem('files', 'issues', row, refresh, utils),
    ],
    [
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'issues', row, refresh, utils),
      getDropdownItem('immutable', 'issues', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'issues', row, refresh, utils),
      getDropdownItem('delete', 'issues', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    slideoverOpenCreate,
    slideoverOpenEdit,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modal,
    modalOpenDelete,
    modalOpenShareFile,
    schema,
    getDropdownItems,
  }
}

export const useIssuesUtils = () => {
  const options = {
    ...optionsIssues,
  }

  const maps = {
    type: toMapByKey(optionsIssues.type, 'value'),
    status: toMapByKey(optionsIssues.status, 'value'),
    priority: toMapByKey(optionsIssues.priority, 'value'),
  }

  return { options, maps }
}
