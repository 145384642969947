import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { merge } from 'es-toolkit/compat'

export const getDefaultFinancialaccount = (data: Partial<Financialaccount>) => {
  return merge({
    tags: [],
    data: getDefaultFinancialaccountData(data?.data ?? {}),
  }, data)
}

export const financialAccountToPaymentAccount = (financialaccount: Partial<FinancialaccountWithRelations>) => {
  return {
    id: financialaccount.id,
    name: financialaccount?.bank?.name ?? '',
    currency: financialaccount?.currency ?? '',
    clabe: financialaccount?.clabe ?? '',
    accountNumber: financialaccount?.accountNumber ?? '',
  }
}

export const optionsFinancialaccounts = {
  type: [
    { value: 'cash', label: 'Efectivo', color: 'green', description: 'Cuenta de efectivo en mano o en caja.' },
    { value: 'bank', label: 'Banco', color: 'blue', description: 'Cuenta bancaria en una institución financiera.' },
    { value: 'credit', label: 'Crédito', color: 'pink', description: 'Cuenta de crédito que permite gastar más de lo que se tiene disponible.' },
    { value: 'loan', label: 'Préstamo', color: 'orange', description: 'Cuenta de préstamo que se debe pagar en cuotas.' },
    { value: 'other', label: 'Otros', color: 'purple', description: 'Cualquier otro tipo de cuenta que no se ajuste a las categorías anteriores.' },
  ],
  subtype: {
    cash: [
      { value: 'cash-office', label: 'Caja oficina', description: 'Efectivo en caja de la oficina.' },
      { value: 'cash-employee', label: 'Caja empleado', description: 'Efectivo en caja de un empleado.' },
    ],
    bank: [
      { value: 'bank-checking', label: 'Cuenta corriente', description: 'Cuenta bancaria utilizada para transacciones diarias y pagos.' },
      { value: 'bank-saving', label: 'Cuenta ahorro', description: 'Cuenta bancaria destinada a ahorrar dinero con intereses.' },
    ],
    credit: [
      { value: 'credit-business', label: 'Crédito empresarial', description: 'Líneas de crédito destinadas a financiar operaciones empresariales.' },
      { value: 'credit-personal', label: 'Crédito personal', description: 'Líneas de crédito personales, como préstamos personales.' },
      { value: 'credit-card', label: 'Tarjeta crédito', description: 'Tarjetas de crédito que permiten realizar ventas a crédito.' },
    ],
    loan: [
      { value: 'loan-business', label: 'Préstamo empresarial', description: 'Préstamos destinados a financiar operaciones empresariales.' },
      { value: 'loan-personal', label: 'Préstamo personal', description: 'Préstamos destinados a individuos para uso personal.' },
      { value: 'loan-mortgage', label: 'Hipoteca', description: 'Préstamos para la ventade bienes inmuebles.' },
      { value: 'loan-auto', label: 'Préstamo auto', description: 'Préstamos destinados a la ventade vehículos.' },
    ],
    other: [
      { value: 'other', label: 'Otros', description: 'Cualquier otro tipo de cuenta financiera.' },
    ],
  },
  creditType: [
    { value: 'revolving', label: 'Revolvente', description: 'Crédito renovable, como tarjetas de crédito. El límite de crédito se renueva a medida que se paga.' },
    { value: 'installment', label: 'Por cuotas', description: 'Crédito que se paga en cuotas fijas a lo largo de un período específico. Ejemplo: préstamos personales.' },
    { value: 'open-ended', label: 'Crédito abierto', description: 'Crédito que permite pedir prestado según sea necesario y pagar según conveniencia. Ejemplo: líneas de crédito.' },
  ],
  interestRateType: [
    { value: 'fixed', label: 'Tasa fija', description: 'Tasa de interés que no cambia durante la vida del préstamo o inversión.' },
    { value: 'variable', label: 'Tasa variable', description: 'Tasa de interés que cambia según las condiciones del mercado.' },
  ],
  paymentInterval: [
    { value: 'weekly', label: 'Semanal', description: 'Pago semanal de la cuota.' },
    { value: 'biweekly', label: 'Quincenal', description: 'Pago quincenal de la cuota.' },
    { value: 'monthly', label: 'Mensual', description: 'Pago mensual de la cuota.' },
    { value: 'quarterly', label: 'Trimestral', description: 'Pago trimestral de la cuota.' },
    { value: 'semiannually', label: 'Semestral', description: 'Pago semestral de la cuota.' },
    { value: 'annually', label: 'Anual', description: 'Pago anual de la cuota.' },
  ],
} satisfies Options

export const optionsFinancialaccountsMap = {
  'Tipo': optionsFinancialaccounts.type,
  'Subtipo': {
    Efectivo: optionsFinancialaccounts.subtype.cash,
    Banco: optionsFinancialaccounts.subtype.bank,
    Crédito: optionsFinancialaccounts.subtype.credit,
    Préstamo: optionsFinancialaccounts.subtype.loan,
    Otro: optionsFinancialaccounts.subtype.other,
  },
  'Tipo de Credito': optionsFinancialaccounts.creditType,
  'Tipo de Interes': optionsFinancialaccounts.interestRateType,
  'Intervalo de pago': optionsFinancialaccounts.paymentInterval,
}

export const relationsFinancialaccounts: Record<string, boolean> = {
  bank: true,
  transactions: true,
  user: true,
  files: true,
  // options: true,
}

export type FinancialaccountWithRelations = Financialaccount & {
  bank: Bank
  transactions: Transaction[]
  user: User
  files: File[]
  options: Option[]
}

export const viewSettingsFinancialaccounts: ViewSettings = {
  tableName: 'financialaccounts',
  typeOptions: optionsShared.views.filter(view => ['grid', 'cards'].includes(view.value)),
  type: 'cards',
  pagination: { page: 1, pageSize: 50 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'subtype',
      label: 'Subtipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'currency',
      label: 'Moneda',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'bank.name',
      label: 'Banco',
      sortable: true,
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
