import { ModalDelete, ModalShareFile, SlideoverInformation, SlideoverPreview, SlideoverPreviewPdf } from '#components'
import type { BlobObject } from '@nuxthub/core'
import type { ComponentProps } from 'vue-component-type-helpers'

export type Row = {
  id: string
  filePdf?: BlobObject
  [k: string]: unknown
}

export type RowUtils = {
  options: Option[] | ComputedRef<Option[]>
  maps: Record<string, unknown[]> | ComputedRef<Record<string, unknown[]>>
  [k: string]: unknown
}

/**
 * A shared composable with common utilities for rows
 */
export const useRows = <T extends Row>(tableName: TableName) => {
  const slideover = useSlideover()
  const modal = useModal()

  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
  } = useApiDB<T>(tableName)

  const slideoverOpenInformation = (tableName: TableName, row: T) => {
    return new Promise<T>((resolve, reject) => {
      try {
        slideover.reset()
        slideover.open(SlideoverInformation, { data: row, tableName, recordId: row.id })
        resolve()
      }
      catch (error) {
        slideover.close()
        reject(error)
      }
    })
  }

  const slideoverOpenFilePreview = async (file: { pathname: string }, refresh: () => Promise<void>, title?: string) => {
    // NOTE: avoid automatic blob creation since it conflicts with slideover closing on submit
    return new Promise<T>((resolve, reject) => {
      try {
        const src = getFileUrl(file)?.href
        slideover.reset()
        slideover.open(SlideoverPreview, {
          src,
          title: title ?? 'Vista previa',
        })
      }
      catch (error) {
        slideover.close()
        reject(error)
      }
    })
  }

  const slideoverOpenFilePreviewPdf = async (row: Row, filePdfIdField: string, refresh: () => Promise<void>, title?: string) => {
    const { id, immutable, [filePdfIdField]: filePdfId = null } = row ?? {}
    // NOTE: avoid automatic blob creation since it conflicts with slideover closing on submit
    const filePdf = await $fetch<File>(`/api/db/files/${filePdfId}`)
    return new Promise<T>((resolve, reject) => {
      try {
        slideover.reset()
        slideover.open(SlideoverPreviewPdf, {
          filePdf,
          filePdfIdField,
          filePdfId,
          downloadAs: `${row?.uid ?? row?.name ?? id}.pdf`,
          readonly: immutable === true,
          title: title ?? 'Vista previa',
          onClickGeneratePdf: async () => {
            let result: T
            try {
              // NOTE: avoid automatic PDF generation since it conflicts with slideover closing on submit
              const body = { id } as T // avoid fetching twice (createFile functions already fetch row by id)

              const { createFilePdf } = useTable(tableName)
              const utils = useTableUtils(tableName)
              const filePdf = await createFilePdf(body, utils)
              await $fetch<T>(`/api/db/${tableName}/${id}`, {
                method: 'PATCH',
                body: { [filePdfIdField]: filePdf.id },
              })

              await refresh() // required to update the file preview and ensure opening preview to new file

              useToastAlert().success('Archivo regenerado con éxito')

              return filePdf
            }
            catch (error) {
              useToastAlert().error('Error al regenerar el archivo')
              console.error(error)
            }
            finally {
              resolve(result)
            }
          },
        })
      }
      catch (error) {
        slideover.close()
        reject(error)
      }
    })
  }

  const modalOpenDelete = (props: ComponentProps<typeof ModalDelete>) => {
    return new Promise<T[]>((resolve, reject) => {
      try {
        modal.open(ModalDelete, {
          title: 'Eliminar registro',
          onSubmit: props?.onSubmit ?? (async (event) => {
            const result = await onSubmitDelete([event.data])
            modal.close()
            resolve(result)
          }),
        })
      }
      catch (error) {
        slideover.close()
        reject(error)
      }
    })
  }

  const modalOpenShareFile = (props: ComponentProps<typeof ModalShareFile>) => {
    return new Promise<T[]>((resolve, reject) => {
      try {
        modal.open(ModalShareFile, {
          title: 'Compartir archivo',
          ...props,
        })
      }
      catch (error) {
        slideover.close()
        reject(error)
      }
    })
  }

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  }
}
