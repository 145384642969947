<script setup lang="ts">
import type { File } from '#netzo/shared/types/db'

const props = defineProps<{
  filePdf: File | undefined
  filePdfIdField: string
  filePdfId: string | null
  downloadAs: string
  title: string
  onClickGeneratePdf?: () => Promise<File>
  readonly?: boolean
}>()

const { user } = useUserSession()
const { moduleId, userModule } = useModules()
const { downloadFileAs } = useBlobFiles()

const inert = computed(() => props.readonly || !['admin', 'edit'].includes(userModule.value?.role))

const file = ref<File | undefined>(props.filePdf)

const loading = ref(false)
const rerenderKey = ref(Math.random())

const iframeSrc = ref(getFileUrl(file.value!)?.href)

const onClick = async () => {
  loading.value = true
  file.value = (await props?.onClickGeneratePdf?.())!
  const src = getFileUrl(file.value)?.href
  if (file.value && src) {
    iframeSrc.value = src
    rerenderKey.value = Math.random()
  }
  loading.value = false
}
</script>

<template>
  <UDashboardSlideover
    :title="title"
    :ui="{ width: 'min-w-[60vw]', title: 'w-full justify-between', body: { padding: 'p-0' } }"
  >
    <template #title>
      <div class="flex items-center">
        <SlideoverTitle :title="title" :inert="inert" />
        <Can :ability="download" :args="[moduleId]">
          <UTooltip text="Descargar PDF">
            <UButton
              variant="ghost"
              square
              size="sm"
              icon="i-mdi-download"
              class="ml-2"
              @click="downloadFileAs(iframeSrc!, downloadAs)"
            />
          </UTooltip>
        </Can>
        <UTooltip text="Abrir en una nueva ventana">
          <UButton
            variant="ghost"
            square
            size="sm"
            icon="i-mdi-open-in-new"
            class="ml-2"
            @click="open(iframeSrc!)"
          />
        </UTooltip>
      </div>
      <UTooltip
        v-if="!inert && iframeSrc && onClickGeneratePdf"
        text="Recargar"
      >
        <UButton
          square
          size="sm"
          icon="i-mdi-refresh"
          :loading="loading"
          class="mr-2"
          @click="onClick"
        >
          Generar PDF
        </UButton>
      </UTooltip>
    </template>

    <iframe
      v-if="iframeSrc"
      :key="rerenderKey"
      class="w-full h-full border-none"
      :src="iframeSrc"
    />
    <ViewPlaceholder
      v-else-if="onClickGeneratePdf"
      icon="i-mdi-file-pdf"
      label="No existe un PDF para mostrar. Genera uno para visualizarlo aquí."
    >
      <template #bottom>
        <UButton
          square
          icon="i-mdi-refresh"
          :loading="loading"
          class="mt-6"
          @click="onClick"
        >
          Generar PDF
        </UButton>
      </template>
    </ViewPlaceholder>
  </UDashboardSlideover>
</template>
