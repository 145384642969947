<script setup lang="ts">
const model = defineModel<string | number>()

const props = defineProps<{
  disabled?: boolean
  icon?: string
  min?: number
  max?: number
  size?: 'xs' | 'sm' | 'md' | 'lg'
  align?: 'right' | 'left' | 'center'
}>()

const emit = defineEmits<{
  (e: 'blur', value: number): void
}>()

const state = computed({
  get: () => Number(model.value) * 100,
  set: (value) => {
    // Clamp value between 0 and 100
    const clamped = Math.min(Math.max(value, 0), 100)
    model.value = Number((clamped / 100).toFixed(2))
  },
})
</script>

<template>
  <UInput
    v-model="state"
    type="number"
    :icon="icon ? 'i-mdi-percent' : undefined"
    :disabled="disabled"
    :min="min ?? 0"
    :max="max ?? 100"
    :step="1"
    v-bind="$attrs"
  />
</template>
