import type { File } from '#netzo/shared/types/db'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'

export const useBlobFiles = () => {
  const zip = new JSZip()

  const downloadAll = async (files: File[] = []) => {
    const promises = files.map(async (file) => {
      const response = await fetch(`/api/blob/${file.pathname}`)
      const blob = await response.blob()
      zip.file(file.pathname?.split('/')?.pop(), blob)
    })
    await Promise.all(promises)
    const content = await zip.generateAsync({ type: 'blob' })
    saveAs(content, `${format(new Date(), 'yyyy-MM-dd')}-archivos.zip`)
  }

  const downloadFileAs = (src: string, filename: string) => {
    fetch(src)
      .then(response => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(url)
      })
      .catch(console.error)
  }

  return { downloadAll, downloadFileAs }
}
