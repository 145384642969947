import type { Options } from '#netzo/shared/types/core'
import type { File } from '#netzo/shared/types/db'
import { createId } from '#netzo/utils/core/db'
import { merge } from 'es-toolkit/compat'

export const getDefaultFile = (data: Partial<File>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    tags: [],
  }, data)
}

export const optionsFiles = {
  tableName: optionsShared.tableName,
} satisfies Options

export const optionsFilesMap = {}

export const relationsFiles: Record<string, boolean> = {}

export type FileWithRelations = File & {}

export const viewSettingsFiles: ViewSettings = {
  tableName: 'files',
  typeOptions: optionsShared.views.filter(view => ['grid'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 50 },
  paginationOptions: optionsShared.page,
  compact: true,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    // {
    //   key: 'tableName',
    //   label: 'Registro',
    //   sortable: true,
    //   class: 'text-center', // for <th>
    //   rowClass: 'text-center', // for <td>
    // },
    // {
    //   key: 'recordId',
    //   label: 'ID del Registro',
    //   sortable: true,
    //   class: 'text-center', // for <th>
    //   rowClass: 'text-center', // for <td>
    // },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'pathname',
      label: 'Vista Previa',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'public',
      label: 'Público',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'contentType',
      label: 'Tipo de Contenido',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'size',
      label: 'Tamaño',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    ...COLUMNS_METADATA,
  ],
}
