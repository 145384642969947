import { AppAccountgroupsSlideover } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppAccountgroupsSlideoverProps = ComponentProps<typeof AppAccountgroupsSlideover>

export type AccountgroupsUtils = ReturnType<typeof useAccountgroupsUtils>

export const useAccountgroups = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Accountgroup>('accountgroups')

  const slideoverOpenCreate = (props: AppAccountgroupsSlideoverProps) => {
    return new Promise<Accountgroup>((resolve, reject) => {
      const { user } = useUserSession()
      const data = getDefaultAccountgroup({userId: user.value.id, ...props?.data})
      slideover.reset()
      slideover.open(AppAccountgroupsSlideover, {
        title: 'Crear grupo',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
            slideover.reset()
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
      })
    })
  }

  const slideoverOpenEdit = (props: AppAccountgroupsSlideoverProps) => {
    return new Promise<Accountgroup>((resolve, reject) => {
      const data = getDefaultAccountgroup(props.data)
      slideover.reset()
      slideover.open(AppAccountgroupsSlideover, {
        title: 'Editar grupo',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
            slideover.reset()
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const schema = z.object({
    name: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Accountgroup, refresh: () => Promise<void>, utils: AccountgroupsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'accountgroups', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'accountgroups', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'accountgroups', row, refresh, utils),
      getDropdownItem('files', 'accountgroups', row, refresh, utils),
    ],
    [
      {
        label: 'Agregar cuenta',
        icon: ICONS.accounts,
        click: () => {
          const accounts$ = useAccounts()
          accounts$.slideoverOpenCreate({
            data: { accountgroupId: row.id },
            disabledFields: ['accountgroupId'],
          }).then(refresh)
        },
      },
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'accountgroups', row, refresh, utils),
      getDropdownItem('immutable', 'accountgroups', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'accountgroups', row, refresh, utils),
      getDropdownItem('delete', 'accountgroups', row, refresh, utils),
    ],
  ].filter(items => items?.length).filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useAccountgroupsUtils = () => {
  const options = {
    ...optionsAccountgroups,
  }

  const maps = {}

  return { options, maps }
}
