import type { Options } from '#netzo/shared/types/core'
import { createId } from '#netzo/utils/core/db'
import { merge } from 'es-toolkit/compat'

export const getDefaultApikey = (data: Partial<Apikey>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    unhashedApikey: createApikey(), // NOTE: POST handler sets hashedApiKey from unhashedApikey
    permission: 'admin',
  }, data)
}

export const optionsApikeys = {
  permission: [
    {
      value: 'admin',
      label: 'Administación',
      description: 'Puede leer, escribir y eliminar registros en todos los módulos.',
      icon: 'i-mdi-shield-account',
      color: 'blue',
    },
    {
      value: 'edit',
      label: 'Escritura',
      description: 'Puede leer y escribir registros en todos los módulos.',
      icon: 'i-mdi-pencil',
      color: 'red',
    },
    {
      value: 'view',
      label: 'Lectura',
      description: 'Solo puede leer registros en todos los módulos.',
      icon: 'i-mdi-eye',
      color: 'green',
    },
  ],
} satisfies Options

export const optionsApikeysMap = {
  Permiso: optionsApikeys.permission,
}

export const relationsApikeys: Record<string, boolean> = {}

export type ApikeyWithRelations = Apikey & {}

export const viewSettingsApikeys: ViewSettings = {
  tableName: 'apikeys',
  typeOptions: optionsShared.views.filter(view => ['grid'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 50 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'description',
      label: 'Descripción',
    },
    {
      key: 'permission',
      label: 'Permiso',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    ...COLUMNS_METADATA,
  ],
}
