import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId, createUid } from '#netzo/utils/core/db'
import type { CalendarEvent } from '@schedule-x/calendar'
import { format, parseISO } from 'date-fns'
import { merge } from 'es-toolkit/compat'

import type { Content, ContentColumns, TDocumentDefinitions, TDocumentInformation } from 'pdfmake/interfaces'
import type { ProductionorderitemWithRelations } from './productionorderitems'

export const getDefaultProductionorder = (data: Partial<Productionorder>) => {
  const uid = createUid(new Date(), 'OP') // IMPORTANT: cloudflare throws error if called on global scope
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'external',
    status: 'draft',
    priority: '2',
    uid: uid,
    name: uid,
    isDatetime: false,
    dateStart: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    dateEnd: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    filePdf: {},
    pdfSettings: {
      ...useAppConfig().netzo.defaults.pdfSettings,
      images: true,
    },
    tags: [],
    data: getDefaultProductionorderData(data?.data ?? {}),
  }, data)
}

export const getProductionorderInformation = (data: Partial<ProductionorderWithRelations>) => {
  if (!data) return []

  const maps = {
    type: toMapByKey(optionsProductionorders.type, 'value'),
    status: toMapByKey(optionsProductionorders.status, 'value'),
    priority: toMapByKey(optionsProductionorders.priority, 'value'),
  }
  const localDateStart = data.dateStart ? parseISO(data.dateStart) : null
  const dateStart = localDateStart
    ? data.isDatetime
      ? localDateStart.toLocaleString()
      : localDateStart.toLocaleDateString()
    : null
  const localDateEnd = data.dateEnd ? parseISO(data.dateEnd) : null
  const dateEnd = localDateEnd
    ? data.isDatetime
      ? localDateEnd.toLocaleString()
      : localDateEnd.toLocaleDateString()
    : null
  return [
    ['Referencia', data.uid],
    ['Nombre', data.name],
    ['Tipo', maps.type.get(data.type!)?.label],
    ['Estado', maps.status.get(data.status!)?.label],
    ['Prioridad', maps.priority.get(data.priority!)?.label],
    ['Fecha de Inicio', dateStart],
    ['Fecha de Cierre', dateEnd],
  ].map(([key, value]) => [key, value || '-'])
}

export const optionsProductionorders = {
  type: [
    { value: 'external', label: 'Externa', description: 'Órdenes originadas de solicitudes de clientes para productos.', icon: 'i-mdi-package-variant', color: 'green' },
    { value: 'internal', label: 'Interna', description: 'Órdenes de trabajo para procesos internos que no están vinculadas a ventas.', icon: 'i-mdi-factory', color: 'blue' },
  ],
  status: [
    { value: 'new', label: 'Nueva', description: 'Orden creada pero pendiente de agendar y preparar.', icon: 'i-mdi-plus-circle', color: 'gray' },
    { value: 'in-progress', label: 'En Ejecución', description: 'Orden en proceso activo de ejecución.', icon: 'i-mdi-progress-clock', color: 'orange' },
    { value: 'approval', label: 'Aprobación', description: 'Orden en espera de aprobación final.', icon: 'i-mdi-check-decagram', color: 'blue' },
    { value: 'rework', label: 'Retrabajo', description: 'Orden con problemas que requieren corrección.', icon: 'i-mdi-replay', color: 'red' },
    { value: 'completed', label: 'Finalizada', description: 'Orden completada con éxito y aprobada para su cierre.', icon: 'i-mdi-check-circle', color: 'green' },
    { value: 'canceled', label: 'Cancelada', description: 'Orden cancelada. No se realizará ninguna acción adicional.', icon: 'i-mdi-close-circle', color: 'red' },
  ],
  priority: optionsShared.priority,
} satisfies Options

export const optionsProductionordersMap = {
  Tipo: optionsProductionorders.type,
  Estado: optionsProductionorders.status,
  Prioridad: optionsProductionorders.priority,
}

export const relationsProductionorders: Record<string, boolean> = {
  account: true,
  deal: true,
  productionorderitems: true,
  salesorder: true,
  user: true,
  filePdf: true,
  files: true,
  // options: true,
}

export type ProductionorderWithRelations = Productionorder & {
  deal: Deal
  productionorderitems: Productionorderitem[]
  salesorder: Salesorder
  user: User
  files: File[]
  options: Option[]
}

export const productionorderToViewCalendarEvent = (calendarId: keyof Productionorder, utils: ProductionordersUtils) => {
  return (row: Productionorder): CalendarEvent => {
    const calendarName = utils.maps[calendarId].get(row[calendarId])?.label?.toUpperCase()
    const dateFormat = row.isDatetime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
    return {
      ...row,
      id: row.id,
      title: `[${calendarName}] ${row.name}`,
      description: row.text!,
      start: format(new Date(row.dateStart), dateFormat),
      end: format(new Date(row.dateEnd), dateFormat),
      calendarId: row[calendarId],
    }
  }
}

export const createPdfmakeDocumentDefinitionProductionorders = async (
  data: ProductionorderWithRelations,
  utils: ProductionordersUtils,
  productionorderitems: ProductionorderitemWithRelations[],
): Promise<TDocumentDefinitions> => {
  data.data ??= {}
  data.productionorderitems ??= []

  // console.log('productionorderitems', productionorderitems.data.value.length)

  const maps = {
    productionorderitems: {
      id: toMapByKey(productionorderitems, 'id'),
      type: toMapByKey(optionsProductionorders.type, 'value'),
      status: toMapByKey(optionsProductionorders.status, 'value'),
      priority: toMapByKey(optionsProductionorders.priority, 'value'),
    },
    salesorder: {
      incoterm: toMapByKey(optionsDeliveries.incoterm, 'value'),
      schedule: toMapByKey(optionsDeliveries.schedule, 'value'),
    },
    fiscalData: {
      satUnitKey: toMapByKey(optionsSAT.productUnitId, 'value'),
    },
  }

  const info: TDocumentInformation = {
    title: `${data.name && data.uid && (data.name !== data.uid) ? `${data.name} (${data.uid})` : data.uid}`,
    subject: 'Orden de Producción La Tinta',
    keywords: 'diseño, agencia, publicidad',
    author: 'LA TINTA',
    producer: 'Netzo (https://netzo.dev)',
  }

  const { pdfSettings } = useAppConfig().netzo.defaults
  const [symbolBase64, logoBase64] = await Promise.all([
    getBase64ImageFromURL(pdfSettings.symbolPng),
    getBase64ImageFromURL(pdfSettings.logoPng),
  ])

  const sectionHeader: ContentColumns = {
    columns: [
      { image: symbolBase64, fit: [80, 200], margin: [5, 5] },
      {
        stack: [
          { text: 'Orden de Producción', fontSize: 18, alignment: 'right', margin: [0, 0, 0, 5] },
          { text: [{ text: 'Nombre: ', bold: true }, data.name], alignment: 'right' },
          { text: [{ text: 'Referencia: ', bold: true }, `${data.uid}`], alignment: 'right' },
          { text: [{ text: 'Responsable: ', bold: true }, data.user?.name], alignment: 'right' },
          { text: [{ text: 'Estado: ', bold: true }, utils.maps.status.get(data.status)?.label], alignment: 'right' },
          { text: [{ text: 'Prioridad: ', bold: true }, utils.maps.priority.get(data.priority)?.label], alignment: 'right' },
          { text: [{ text: 'Programación: ', bold: true }, new Date(data.dateStart).toLocaleDateString(), ' - ', new Date(data.dateEnd).toLocaleDateString()], alignment: 'right' },
        ],
        margin: [0, 10],
      },
    ],
    margin: [0, 0, 0, 5],
  }

  const sectionProductionorderitems: Content = {
    table: {
      dontBreakRows: false,
      headerRows: 0,
      widths: data.pdfSettings?.images ? ['50%', '50%'] : ['*'],
      body: [
        ...(await Promise.all(data!.productionorderitems!.map(async (item: Productionorderitem, index: number) => [
          ...data.pdfSettings?.images ? [
            {
              stack: [
                await Promise.all(
                  item.images.map(async (image) => {
                    if (image) {
                      return {
                        image: await getBase64ImageFromURL(image),
                        fit: [250, 250],
                        margin: [5, 5, 5, 5],
                        alignment: 'center',
                      }
                    }
                    return null // Explicitly return null for falsy values
                  }),
                ),
              ].filter(Boolean), // Apply filter after awaiting Promise.all
            },
          ] : [],
          {
            stack: [
              { text: [{ text: 'Articulo: ', bold: true }, maps.productionorderitems.id.get(item.id)?.productitem.name], margin: [5, 2] },
              { text: [{ text: 'Nombre: ', bold: true }, item.name], margin: [5, 2] },
              { text: [{ text: 'Nombre del Archivo: ', bold: true }, item.fileName], margin: [5, 2] },
              { text: [{ text: 'SKU: ', bold: true }, item.sku], margin: [5, 2] },
              { text: [{ text: 'Unidad: ', bold: true }, item.unit], margin: [5, 2] },
              { text: [{ text: 'Cantidad (Plan): ', bold: true }, item.quantityPlanned], margin: [5, 2] },
              { text: [{ text: 'Cantidad (Real): ', bold: true }, item.quantityActual], margin: [5, 2] },
              { text: [{ text: 'Medidas: ', bold: true }, item.attributes?.measurements], margin: [5, 2] },
              { text: [{ text: 'Especificaciones: ', bold: true }, item.text], margin: [5, 2] },
              { text: [{ text: 'Control de Calidad: ', bold: true }], margin: [5, 2, 5, 1] },
              getChecklist(item.data?.items, 3),
            ].filter(Boolean),
          },
        ]))),
      ].filter(Boolean),
    },
    layout: {
      defaultBorder: true,
    },
  }

  return {
    language: 'es-MX',
    compress: true,
    watermark: data.pdfSettings?.watermark?.enabled
      ? {
          text: data.pdfSettings?.watermark.text,
          color: data.pdfSettings?.watermark.color,
          bold: data.pdfSettings?.watermark.bold,
          italics: data.pdfSettings?.watermark.italic,
          opacity: 0.05,
        }
      : undefined,
    info,
    content: [
      sectionHeader,
      data.productionorderitems.length && sectionProductionorderitems,
    ],
    footer: (currentPage: number, pageCount: number) => ({
      columns: [
        { image: logoBase64, fit: [80, 20], margin: [20, 10, 0, 0] },
        {
          stack: [
            { text: [
              { text: 'Generado con ' },
              { text: 'netzo.dev', link: 'https://netzo.dev', color: 'blue' },
              { text: ' ' },
              { text: `el ${new Date().toLocaleDateString()}` }],
            }],
          alignment: 'center',
          margin: [0, 10, 0, 0],
        },
        {
          text: `Página ${currentPage.toString()} de ${pageCount}`,
          alignment: 'right',
          margin: [0, 10, 20, 0],
        },
      ],
      widths: ['auto', '*', 'auto'],
    }),
    styles: {
      header: { fontSize: 10, bold: true, margin: [0, 0, 10, 5] },
      tableHeader: { bold: true, fillColor: '#aaa', fillOpacity: 0.25, margin: [5, 5], noWrap: true },
      tableRow: { bold: false, margin: [5, 5] },
      tableRowDetails: { fontSize: 6, margin: [5, 1, 5, 1] },
    },
    defaultStyle: { fontSize: 8 },
    pageSize: data.pdfSettings?.pageSize || 'LETTER',
    pageMargins: [20, 20, 20, 40],
  }
}

export const viewSettingsProductionorders: ViewSettings = {
  tableName: 'productionorders',
  typeOptions: optionsShared.views.filter(view => ['grid', 'kanban', 'calendar'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 50 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
      rowClass: 'text-xs', // for <td>
    },
    {
      key: 'filePdf',
      label: 'PDF',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'priority',
      label: 'Prioridad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateStart',
      label: 'Fecha de Inicio',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateEnd',
      label: 'Fecha de Cierre',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'productionorderitems.status',
      label: 'Items',
    },
    {
      key: 'user.name',
      label: 'Responsable',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'account.name',
      label: 'Cuenta',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'text',
      label: 'Detalles',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groupBy: 'status',
  groupByOptions: [
    { label: 'Tipo', value: 'type', options: optionsProductionorders.type },
    { label: 'Estado', value: 'status', options: optionsProductionorders.status },
    { label: 'Prioridad', value: 'priority', options: optionsProductionorders.priority },
  ],
}
