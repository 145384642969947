import { AppSalesorderitemsSlideover, AppSalesorderitemsSlideoverItems } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppSalesorderitemsSlideoverProps = ComponentProps<typeof AppSalesorderitemsSlideover>
type AppSalesorderitemsSlideoverItemsProps = ComponentProps<typeof AppSalesorderitemsSlideoverItems>

export type SalesorderitemsUtils = ReturnType<typeof useSalesorderitemsUtils>

export const useSalesorderitems = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Salesorderitem>('salesorderitems')

  const slideoverOpenCreate = (props: AppSalesorderitemsSlideoverProps) => {
    return new Promise<Salesorderitem>((resolve, reject) => {
      const data = getDefaultSalesorderitem(props.data)
      slideover.reset()
      slideover.open(AppSalesorderitemsSlideover, {
        type: 'POST',
        title: 'Agregar item',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
            slideover.reset()
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
      })
    })
  }

  const slideoverOpenEdit = (props: AppSalesorderitemsSlideoverProps) => {
    return new Promise<Salesorderitem>((resolve, reject) => {
      const data = getDefaultSalesorderitem(props.data)
      slideover.reset()
      slideover.open(AppSalesorderitemsSlideover, {
        title: 'Editar item',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
            slideover.reset()
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const slideoverOpenItems = (props: AppSalesorderitemsSlideoverItemsProps) => {
    return new Promise<Salesorder>((resolve, reject) => {
      const data = getDefaultSalesorder(props.data)
      slideover.reset()
      slideover.open(AppSalesorderitemsSlideoverItems, {
        title: 'Agregar items',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
            slideover.reset()
          }
        }),
      })
    })
  }

  const schemaProducts = z.object({
    type: z.string(),
    subtype: z.string(),
    name: z.string(),
    unit: z.string(),
    fiscalData: fiscalDataSchema,
    quantity: z.number(),
    unitPrice: z.number(),
    amount: z.number(),
    salesorderId: z.string(),
    productitemId: z.string(),
  })

  const schemaServices = z.object({
    type: z.string(),
    subtype: z.string(),
    name: z.string(),
    unit: z.string(),
    fiscalData: fiscalDataSchema,
    quantity: z.number(),
    unitPrice: z.number(),
    amount: z.number(),
    salesorderId: z.string(),
    serviceitemId: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Salesorderitem, refresh: () => Promise<void>, utils: SalesorderitemsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'salesorderitems', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'salesorderitems', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'salesorderitems', row, refresh, utils),
      getDropdownItem('files', 'salesorderitems', row, refresh, utils),
    ],
    [
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'salesorderitems', row, refresh, utils),
      {
        label: `${row.immutable ? 'Habilitar' : 'Deshabilitar'}`,
        icon: row.immutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        disabled: row?.salesorder?.immutable,
        click: async () => {
          await onSubmitUpdate({ ...row, immutable: !row.immutable })
          await refresh()
        },
      },
    ],
    [
      getDropdownItem('archive', 'salesorderitems', row, refresh, utils),
      getDropdownItem('delete', 'salesorderitems', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    slideoverOpenEdit,
    slideoverOpenItems,
    schemaProducts,
    schemaServices,
    getDropdownItems,
  }
}

export const useSalesorderitemsUtils = ({ $pricelists, $productitems, $quoteitems, $serviceitems }: {
  $pricelists?: Awaited<ReturnType<typeof useFetch<PricelistWithRelations[]>>>
  $productitems?: Awaited<ReturnType<typeof useFetch<Productitem[]>>>
  $quoteitems?: Awaited<ReturnType<typeof useFetch<Quoteitem[]>>>
  $serviceitems?: Awaited<ReturnType<typeof useFetch<Serviceitem[]>>>
} = {}) => {
  const options = {
    ...optionsSalesorderitems,
    unit: optionsShared.unit,
    pricelistId: computed(() => $pricelists?.data.value.map(toOption)),
    productitemId: computed(() => $productitems?.data.value.map(toOption)),
    quoteitemId: computed(() => $quoteitems?.data.value.map(toOption)),
    serviceitemId: computed(() => $serviceitems?.data.value.map(toOption)),
  }

  const maps = {
    unit: toMapByKey(optionsShared.unit, 'value'),
    type: toMapByKey(optionsSalesorderitems.type, 'value'),
    subtype: toMapByKeyFromObject(optionsSalesorderitems.subtype, 'value'),
    fiscalData: {
      satUnitKey: toMapByKey(optionsSAT.productUnitId, 'value'),
      satTaxObject: toMapByKey(optionsSAT.taxObject, 'value'),
    },
    taxTypes: toMapByKey(optionsSAT.taxTypes, 'value'),
    taxFactor: toMapByKey(optionsSAT.taxFactor, 'value'),
    pricelistId: computed(() => toMapByKey($pricelists?.data.value, 'id')),
    productitemId: computed(() => toMapByKey($productitems?.data.value, 'id')),
    quoteitemId: computed(() => toMapByKey($quoteitems?.data.value, 'id')),
    serviceitemId: computed(() => toMapByKey($serviceitems?.data.value, 'id')),
    quoteitemsByType: computed(() => toMapByGroupKey($quoteitems?.data.value, 'type')),
    pricelistsByType: computed(() => toMapByGroupKey($pricelists?.data.value, 'type')),
    productitemsByProductType: computed(() => toMapByNestedKey($productitems?.data.value, 'product', 'type')),
    serviceitemsByServiceType: computed(() => toMapByNestedKey($serviceitems?.data.value, 'service', 'type')),
  }

  return { options, maps }
}
