import { AppProductionordersSlideover } from '#components'
import { z, type ProductionorderWithRelations } from '#imports'
import type { ProductionorderitemWithRelations } from '#netzo/utils/app/productionorderitems'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppProductionordersSlideoverProps = ComponentProps<typeof AppProductionordersSlideover>

export type ProductionordersUtils = ReturnType<typeof useProductionordersUtils>

export const useProductionorders = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Productionorder>('productionorders')

  const slideoverOpenCreate = (props: AppProductionordersSlideoverProps) => {
    return new Promise<Productionorder>((resolve, reject) => {
      const { user } = useUserSession()
      const data = getDefaultProductionorder({ userId: user.value.id, ...props?.data })
      slideover.reset()
      slideover.open(AppProductionordersSlideover, {
        title: 'Registrar orden de producción',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
            slideover.reset()
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
      })
    })
  }

  const slideoverOpenEdit = (props: AppProductionordersSlideoverProps) => {
    return new Promise<Productionorder>((resolve, reject) => {
      const data = getDefaultProductionorder(props.data)
      slideover.reset()
      slideover.open(AppProductionordersSlideover, {
        title: 'Editar orden de producción',
        ...props,
        data,
        disabledFields: ['type'],
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
            slideover.reset()
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const schemaInternal = z.object({
    type: z.literal('internal'), // Literal "outbound" type
    status: z.string(),
    priority: z.string(),
    name: z.string(),
    dateStart: z.string(),
    dateEnd: z.string(),
  })

  const schemaExternal = z.object({
    type: z.literal('external'), // Literal "inbound" type
    status: z.string(),
    priority: z.string(),
    name: z.string(),
    dateStart: z.string(),
    dateEnd: z.string(),
    accountId: z.string(),
    dealId: z.string(),
    salesorderId: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Productionorder, refresh: () => Promise<void>, utils: ProductionordersUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'productionorders', row, refresh, utils),
      getDropdownItem('filePdf', 'productionorders', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'productionorders', row, refresh, utils),
      getDropdownItem('copyUid', 'productionorders', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'productionorders', row, refresh, utils),
      getDropdownItem('files', 'productionorders', row, refresh, utils),
    ],
    [
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'productionorders', row, refresh, utils),
      getDropdownItem('immutable', 'productionorders', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'productionorders', row, refresh, utils),
      getDropdownItem('delete', 'productionorders', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  // files:

  const createPdf = async (data: ProductionorderWithRelations, utils: ProductionordersUtils) => {
    const productionorderitems = await $fetch<ProductionorderitemWithRelations[]>('/api/db/productionorderitems', {
      query: { productionorderId: data.id, $with: relationsProductionorderitems },
    })

    console.log('productionorderitems', productionorderitems)

    const documentDefinition = await createPdfmakeDocumentDefinitionProductionorders(data, utils, productionorderitems!)
    const pdf = await usePDFMake().createPdf(documentDefinition)
    return pdf
  }

  // updates the productionorder.files array (to be used after POST/PATCH)
  async function createFilePdf(data: Partial<Productionorder>, utils: ProductionordersUtils) {
    const { uploadFile } = useApiBlob('productionorders', data.id)
    const [existingData] = await $fetch<ProductionorderWithRelations[]>(`/api/db/productionorders`, {
      query: { id: data.id, $with: relationsProductionorders },
    })
    const newData = { ...existingData, ...data } as ProductionorderWithRelations
    const pdf = await createPdf(newData, utils)
    // IMPORTANT: wrap with a Promise to await the blob
    const blob: Blob = await new Promise(resolve => pdf.getBlob(blob => resolve(blob)))
    const file = new File([blob], 'productionorder.pdf', { type: 'application/pdf' })
    return uploadFile('filePdf', file)
  }

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    slideoverOpenCreate,
    slideoverOpenEdit,
    modal,
    modalOpenDelete,
    modalOpenShareFile,
    schemaInternal,
    schemaExternal,
    getDropdownItems,
    createPdf,
    createFilePdf,
  }
}

export const useProductionordersUtils = ({ $accounts, $deals, $salesorders, $users }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $deals?: Awaited<ReturnType<typeof useFetch<Deal[]>>>
  $salesorders?: Awaited<ReturnType<typeof useFetch<Salesorder[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsProductionorders,
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    dealId: computed(() => $deals?.data.value.map(toOption)),
    salesorderId: computed(() => $salesorders?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsProductionorders.type, 'value'),
    status: toMapByKey(optionsProductionorders.status, 'value'),
    priority: toMapByKey(optionsProductionorders.priority, 'value'),
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
    dealId: computed(() => toMapByKey($deals?.data.value, 'id')),
    salesorderId: computed(() => toMapByKey($salesorders?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
    dealsByAccountId: computed(() => toMapByGroupKey($deals?.data.value, 'accountId')),
    salesordersByAccountId: computed(() => toMapByGroupKey($salesorders?.data.value, 'accountId')),
    salesorderByDealId: computed(() => toMapByGroupKey($salesorders?.data.value, 'dealId')),
  }

  return { options, maps }
}
