import { AppBranchesSlideover } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppBranchesSlideoverProps = ComponentProps<typeof AppBranchesSlideover>

export type BranchesUtils = ReturnType<typeof useBranchesUtils>

export const useBranches = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Branch>('branches')

  const slideoverOpenCreate = (props: AppBranchesSlideoverProps) => {
    return new Promise<Branch>((resolve, reject) => {
      const data = getDefaultBranch(props.data)
      slideover.reset()
      slideover.open(AppBranchesSlideover, {
        title: 'Crear sucursal',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
            slideover.reset()
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
      })
    })
  }

  const slideoverOpenEdit = (props: AppBranchesSlideoverProps) => {
    return new Promise<Branch>((resolve, reject) => {
      const data = getDefaultBranch(props.data)
      slideover.reset()
      slideover.open(AppBranchesSlideover, {
        title: 'Editar sucursal',
        ...props,
        data,
        disabledFields: ['accountId'],
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
            slideover.reset()
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const schema = z.object({
    name: z.string(),
    address: addressSchema,
    accountId: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Branch, refresh: () => Promise<void>, utils: BranchesUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'branches', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'branches', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'branches', row, refresh, utils),
      getDropdownItem('files', 'branches', row, refresh, utils),
    ],
    [
      {
        label: 'Agregar contacto',
        icon: ICONS.contacts,
        disabled: !row.accountId,
        click: () => {
          const contacts$ = useContacts()
          contacts$.slideoverOpenCreate({
            data: { moduleId: [moduleId.value], accountId: row.accountId, branchId: row.id },
            disabledFields: ['type', 'accountId', 'branchId'],
          }).then(refresh)
        },
      },
      {
        label: 'Crear negocio',
        icon: ICONS.deals,
        disabled: !row.accountId,
        click: () => {
          const deals$ = useDeals()
          deals$.slideoverOpenCreate({
            data: {
              type: 'outbound',
              accountId: row.accountId ?? undefined,
              branchId: row.id,
            },
            disabledFields: ['type', 'accountId', 'branchId'],
          }).then(refresh)
        },
      },
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'branches', row, refresh, utils),
      getDropdownItem('immutable', 'branches', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'branches', row, refresh, utils),
      getDropdownItem('delete', 'branches', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useBranchesUtils = ({ $accounts }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
} = {}) => {
  const options = {
    ...optionsBranches,
    accountId: computed(() => $accounts?.data.value.map(toOption)),
  }

  const maps = {
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
  }

  return { options, maps }
}
