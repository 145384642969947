<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { merge } from 'es-toolkit/compat'

const props = defineProps<{
  action: 'create' | 'edit'
  data: Partial<Deal>
  onSubmit: (event: FormSubmitEvent<Partial<Deal>>) => void
  openNestedCallback: (data: Partial<Deal>) => void
  title?: string
  disabledFields?: (keyof Deal | string)[]
  readonly?: boolean
  navigateToSubpage?: boolean
}>()

const router = useRouter()
const { user } = useUserSession()
const { moduleId, userModule } = useModules()
const slideover = useSlideover()

const inert = computed(() => props.readonly || props.data?.immutable || !['admin', 'edit'].includes(userModule.value?.role))
const simple = ref(true)

const state = ref<Partial<Deal>>(props.data)

const deals$ = useDeals()

const $accounts = await useFetch<Account[]>('/api/db/accounts', {
  query: {},
  default: () => [],
})
const $branches = await useFetch<Branch[]>('/api/db/branches', {
  query: {}, default: () => [],
})
const $campaigns = await useFetch<Campaign[]>('/api/db/campaigns', {
  query: {}, default: () => [],
})
const $contacts = await useFetch<Contact[]>('/api/db/contacts', {
  query: { moduleId: [moduleId.value] },
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  query: { type: ['internal'], $columns: ['id', 'name', 'image', 'email'] },
  default: () => [],
})

const utils = useDealsUtils({ $accounts, $branches, $campaigns, $contacts, $users })

const items = computed<AccordionItem[]>(() => {
  if (['create'].includes(props.action) && simple.value) {
    return [{ slot: 'simple', label: 'Creación rápida', icon: 'i-mdi-lightning-bolt', defaultOpen: true }]
  }
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.deals,
      defaultOpen: true,
    },
    {
      slot: 'metrics',
      label: 'Métricas',
      icon: 'i-mdi-chart-line',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Datos adicionales',
      icon: 'i-mdi-code-json',
      defaultOpen: false,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const loading = ref(false)

const onUpdateCampaignId = async (campaignId: string) => {
  const campaign = utils.maps.campaignId.value.get(campaignId) as Partial<Campaign>
  state.value.metrics = {
    ...state.value.metrics,
    frequency: campaign?.metrics?.frequency,
    probability: campaign?.metrics?.probability,
    value: campaign?.metrics?.value,
  }
}

const onSubmitDeal = async (event: FormSubmitEvent<Partial<Deal>>) => {
  event.data = merge(state.value, event.data) // WORKAROUND: UForm drops nested props on validation
  loading.value = true
  await props.onSubmit(event)
  if (props.navigateToSubpage) {
    router.push(`/sales/deals/${event.data.id}`)
  }
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.deals"
      :validate-on="['submit']"
      :schema="deals$.schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitDeal"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #simple>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(deals$.schema, 'name')"
            :hint="state.uid"
          >
            <UButtonGroup class="flex">
              <InputText
                v-model.defaultcase="state.name"
                :disabled="disabledFields?.includes('name')"
                autofocus
                class="flex-1"
              />
              <ButtonGenerateString @click="state.name = state.uid" />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(deals$.schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Cuenta"
            name="accountId"
            :required="isRequired(deals$.schema, 'accountId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'accounts',
                idField: 'accountId',
                utils: utils,
                options: utils.options.accountId.value,
                disabled: disabledFields?.includes('accountId'),
                slideoverOpenCreateProps: {},
                refresh: $accounts.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Subtipo"
            name="subtype"
            :required="isRequired(deals$.schema, 'subtype')"
          >
            <SelectMenuBaseInfo
              v-model="state.subtype"
              :options="utils.options.subtype"
              :disabled="disabledFields?.includes('subtype')"
            />
          </UFormGroup>

          <UFormGroup
            label="Contacto"
            name="contactId"
            :required="isRequired(deals$.schema, 'contactId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'contacts',
                idField: 'contactId',
                utils: utils,
                options: utils.maps.contactsByAccountId.value.get(state.accountId)?.map(toOption) ?? [],
                disabled: disabledFields?.includes('contactId') || !state.accountId,
                slideoverOpenCreateProps: {
                  data: { moduleId: 'sales', accountId: state.accountId, branchId: state.branchId, userId: state.userId },
                  disabledFields: [],
                },
                refresh: $contacts.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Responsable"
            name="userId"
            :required="isRequired(deals$.schema, 'userId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'users',
                idField: 'userId',
                utils: utils,
                options: utils.options.userId.value ?? [],
                disabled: disabledFields?.includes('userId'),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Campaña"
            name="campaignId"
            :required="isRequired(deals$.schema, 'campaignId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'campaigns',
                idField: 'campaignId',
                utils: utils,
                options: utils.options.campaignId.value,
                disabled: disabledFields?.includes('campaignId'),
                onClickUpdate: onUpdateCampaignId,
                slideoverOpenCreateProps: {},
                refresh: $campaigns.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <SlideoverButtonExpand @click="simple = false" />
        </template>

        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(deals$.schema, 'name')"
            :hint="state.uid"
          >
            <UButtonGroup class="flex">
              <InputText
                v-model.defaultcase="state.name"
                :disabled="disabledFields?.includes('name')"
                autofocus
                class="flex-1"
              />
              <ButtonGenerateString @click="state.name = state.uid" />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(deals$.schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Cuenta"
            name="accountId"
            :required="isRequired(deals$.schema, 'accountId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'accounts',
                idField: 'accountId',
                utils: utils,
                options: utils.options.accountId.value,
                disabled: disabledFields?.includes('accountId'),
                slideoverOpenCreateProps: {},
                refresh: $accounts.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Subtipo"
            name="subtype"
            :required="isRequired(deals$.schema, 'subtype')"
          >
            <SelectMenuBaseInfo
              v-model="state.subtype"
              :options="utils.options.subtype"
              :disabled="disabledFields?.includes('subtype')"
            />
          </UFormGroup>

          <UFormGroup
            label="Sucursal"
            name="branchId"
            :required="isRequired(deals$.schema, 'branchId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'branches',
                idField: 'branchId',
                utils: utils,
                options: utils.maps.branchesByAccountId.value.get(state.accountId)?.map(toOption) ?? [],
                disabled: disabledFields?.includes('branchId') || !state.accountId,
                slideoverOpenCreateProps: {
                  data: { accountId: state.accountId },
                  disabledFields: ['accountId'],
                },
                refresh: $branches.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Contacto"
            name="contactId"
            :required="isRequired(deals$.schema, 'contactId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'contacts',
                idField: 'contactId',
                utils: utils,
                options: utils.maps.contactsByAccountId.value.get(state.accountId)?.map(toOption) ?? [],
                disabled: disabledFields?.includes('contactId') || !state.accountId,
                slideoverOpenCreateProps: {
                  data: { moduleId: 'sales', accountId: state.accountId, branchId: state.branchId, userId: state.userId },
                  disabledFields: [],
                },
                refresh: $contacts.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Responsable"
            name="userId"
            :required="isRequired(deals$.schema, 'userId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'users',
                idField: 'userId',
                utils: utils,
                options: utils.options.userId.value ?? [],
                disabled: disabledFields?.includes('userId'),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Campaña"
            name="campaignId"
            :required="isRequired(deals$.schema, 'campaignId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'campaigns',
                idField: 'campaignId',
                utils: utils,
                options: utils.options.campaignId.value,
                disabled: disabledFields?.includes('campaignId'),
                onClickUpdate: onUpdateCampaignId,
                slideoverOpenCreateProps: {},
                refresh: $campaigns.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>
        </template>

        <template #details>
          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(deals$.schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="utils.options.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Prioridad"
            name="priority"
            :required="isRequired(deals$.schema, 'priority')"
          >
            <SelectMenuBaseInfo
              v-model="state.priority"
              :options="utils.options.priority"
              :disabled="disabledFields?.includes('priority')"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              table-name="deals"
              :record-id="state.id"
              accept="*"
            />
          </UFormGroup>

          <UFormGroup
            label="Comentarios"
            name="text"
            :required="isRequired(deals$.schema, 'text')"
          >
            <UTextarea
              v-model="state.text"
              autoresize
              :disabled="disabledFields?.includes('text')"
            />
          </UFormGroup>
        </template>

        <template #metrics>
          <UFormGroup
            label="Frecuencia de negocio"
            name="metrics.frequency"
            :required="isRequired(deals$.schema, 'metrics.frequency')"
          >
            <SelectMenuBaseInfo
              v-model="state.metrics.frequency"
              clearable
              :options="utils.options.metrics.frequency"
              :disabled="disabledFields?.includes('metrics.frequency')"
            />
          </UFormGroup>

          <UFormGroup
            label="Probabilidad de cierre"
            name="metrics.probability"
            :required="isRequired(deals$.schema, 'metrics.probability')"
          >
            <InputPercent
              v-model="state.metrics.probability"
              icon
              :disabled="disabledFields?.includes('metrics.probability')"
            />
          </UFormGroup>

          <UFormGroup
            :label="`Valor estimado (${useAppConfig().netzo.defaults.currency})`"
            name="metrics.value"
            :required="isRequired(deals$.schema, 'metrics.value')"
          >
            <InputCurrency
              v-model="state.metrics.value"
              icon
              :disabled="disabledFields?.includes('metrics.value')"
            />
          </UFormGroup>

          <UFormGroup
            :label="`Valor real (${useAppConfig().netzo.defaults.currency})`"
            name="metrics.valueReal"
            :required="isRequired(deals$.schema, 'metrics.valueReal')"
          >
            <InputCurrency
              v-model="state.metrics.valueReal"
              icon
              :disabled="disabledFields?.includes('metrics.valueReal')"
            />
          </UFormGroup>

          <UFormGroup
            label="Razón de pérdida"
            name="metrics.lossReason"
            :required="isRequired(deals$.schema, 'metrics.lossReason')"
          >
            <SelectMenuBaseInfo
              v-model="state.metrics.lossReason"
              clearable
              :options="utils.options.metrics.lossReason"
              :disabled="disabledFields?.includes('metrics.lossReason')"
            />
          </UFormGroup>
        </template>

        <template #data>
          <FieldsetData v-model="state.data" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          color="gray"
          label="Cancelar"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.deals"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
