import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId } from '#netzo/utils/core/db'
import type { CalendarEvent } from '@schedule-x/calendar'
import { format } from 'date-fns'
import { merge } from 'es-toolkit/compat'

export const getDefaultTask = (data: Partial<Task>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    status: 'new',
    priority: '2',
    isDatetime: false,
    dateStart: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    dateEnd: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    tags: [],
  }, data)
}

export const optionsTasks = {
  tableName: optionsShared.tableName,
  status: [
    {
      value: 'new',
      label: 'Nuevo',
      description: 'La tarea es nueva y aún no ha sido programada.',
      icon: 'i-mdi-circle-outline',
      color: 'gray',
    },
    {
      value: 'scheduled',
      label: 'Programado',
      description: 'La tarea ha sido programada para ser atendida.',
      icon: 'i-mdi-dots-horizontal-circle-outline',
      color: 'red',
    },
    {
      value: 'in-progress',
      label: 'En progreso',
      description: 'La tarea está en proceso de ser completada.',
      icon: 'i-mdi-progress-clock',
      color: 'orange',
    },
    {
      value: 'waiting',
      label: 'Esperando',
      description: 'La tarea está esperando una respuesta o acción.',
      icon: 'i-mdi-clock',
      color: 'amber',
    },
    {
      value: 'stuck',
      label: 'Atorado',
      description: 'La tarea está atascada y necesita ser revisada.',
      icon: 'i-mdi-clock-alert',
      color: 'purple',
    },
    {
      value: 'completed',
      label: 'Completado',
      description: 'La tarea se ha completado con éxito.',
      icon: 'i-mdi-check-circle',
      color: 'green',
    },
  ],
  priority: [
    {
      value: '1',
      label: 'Baja',
      description: 'No es importante y puede esperar',
      icon: 'i-mdi-arrow-down-circle',
      color: 'green',
    },
    {
      value: '2',
      label: 'Media',
      description: 'Es importante pero puede esperar',
      icon: 'i-mdi-arrow-right-circle',
      color: 'amber',
    },
    {
      value: '3',
      label: 'Alta',
      description: 'Es importante y necesita ser atendido pronto',
      icon: 'i-mdi-arrow-up-circle',
      color: 'orange',
    },
    {
      value: '4',
      label: 'Critica',
      description: 'Es urgente, necesita ser atendido de inmediato',
      icon: 'i-mdi-alert',
      color: 'red',
    },
  ],
} satisfies Options

export const optionsTasksMap = {
  Estado: optionsTasks.status,
  Prioridad: optionsTasks.priority,
}

export const relationsTasks: Record<string, boolean> = {
  user: true,
  files: true,
  // options: true,
}

export type TaskWithRelations = Task & {
  user: User
  files: File[]
  options: Option[]
}

export const taskToViewCalendarEvent = (calendarId: keyof Task, utils: TasksUtils) => {
  return (row: Task): CalendarEvent => {
    const calendarName = utils.maps[calendarId].get(row[calendarId])?.label?.toUpperCase()
    const dateFormat = row.isDatetime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
    return {
      ...row,
      id: row.id,
      title: `[${calendarName}] ${row.name}`,
      description: row.description!,
      start: format(new Date(row.dateStart), dateFormat),
      end: format(new Date(row.dateEnd), dateFormat),
      calendarId: row[calendarId],
    }
  }
}

export const viewSettingsTasks: ViewSettings = {
  tableName: 'tasks',
  typeOptions: optionsShared.views.filter(view => ['grid', 'kanban', 'calendar'].includes(view.value)),
  type: 'kanban',
  pagination: { page: 1, pageSize: 50 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'priority',
      label: 'Prioridad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'dateStart',
      label: 'Inicio',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateEnd',
      label: 'Fin',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groupBy: 'status',
  groupByOptions: [
    { label: 'Estado', value: 'status', options: optionsTasks.status },
    { label: 'Prioridad', value: 'priority', options: optionsTasks.priority },
  ],
}
