import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId } from '#netzo/utils/core/db'
import { merge } from 'es-toolkit/compat'

export const getDefaultUsernotification = (data: Partial<Usernotification>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'info',
    name: '',
    description: '',
    optionsLocal: {},
    optionsPush: {},
  }, data)
}

export const optionsUsernotifications = {} satisfies Options

export const optionsUsernotificationsMap = {
  Tipo: optionsNotifications.type,
}

export const relationsUsernotifications: Record<string, boolean> = {
  notification: true,
  user: true,
  files: true,
  // options: true,
}

export type UsernotificationWithRelations = Usernotification & {
  notification: Notification
  user: User
  files: File[]
  options: Option[]
}

export const viewSettingsNotifications: ViewSettings = {
  tableName: 'usernotifications',
  typeOptions: optionsShared.views.filter(view => ['grid'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 50 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'notificationType',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'notificationName',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'notificationDescription',
      label: 'Descripción',
    },
    ...COLUMNS_METADATA,
  ],
}
