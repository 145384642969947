import { AppFilesSlideover, AppFilesSlideoverList } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { BlobPutOptions } from '@nuxthub/core'
import type { UseFileDialogOptions } from '@vueuse/core'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppFilesSlideoverProps = ComponentProps<typeof AppFilesSlideover>
type AppFilesSlideoverListProps = ComponentProps<typeof AppFilesSlideoverList>

export type FilesUtils = ReturnType<typeof useFilesUtils>

export const useFiles = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<File>('files')

  const slideoverOpenUpload = (
    tableName: TableName,
    recordId: string,
    type: string,
    _public = false,
    options: BlobPutOptions = {},
    fileDialogOptions: UseFileDialogOptions = {},
  ) => {
    const { files, open, reset, onCancel, onChange } = useFileDialog(fileDialogOptions)
    const { uploadFile } = useApiBlob(tableName, recordId)
    return new Promise<File>((resolve, reject) => {
      open()
      onChange(async (fileList) => {
        const files = fileList ? Array.from(fileList) : []
        try {
          const result = await Promise.all(files.map(file =>
            uploadFile(type, file, { public: _public }, options)),
          )
          resolve(result)
        }
        catch (error) {
          reject(error)
        }
        finally {
          reset() // ensures file dialog is always reset
        }
      })
      onCancel(() => reject())
    })
  }

  const slideoverOpenEdit = (props: AppFilesSlideoverProps) => {
    return new Promise<File>((resolve, reject) => {
      const data = getDefaultFile(props.data)
      slideover.reset()
      slideover.open(AppFilesSlideover, {
        title: 'Editar archivo',
        disabledFields: ['type'],
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
            slideover.reset()
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const slideoverOpenList = (props: AppFilesSlideoverListProps) => {
    return new Promise<File>((resolve, reject) => {
      try {
        slideover.reset()
      slideover.open(AppFilesSlideoverList, {
          title: 'Archivos del registro',
          ...props,
        })
        resolve()
      }
      catch (error) {
        slideover.close()
        reject(error)
      }
    })
  }

  const schema = z.object({
    tableName: z.string(),
    recordId: z.string(),
    pathname: z.string().refine((value) => {
      const prefix = `${value.split('/').slice(0, -1).join('/')}/` // e.g. `accounts/abc123456789/`
      return prefix.length < value.length
    }, { message: 'Nombre de archivo inválido' }),
    contentType: z.string(),
    size: z.number(),
    public: z.boolean(),
  })

  // actions:

  const getDropdownItems = (row: File, refresh: () => Promise<void>, utils: FilesUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'files', row, refresh, utils),
      getDropdownItem('toggleFileVisibility', 'files', row, refresh, utils),
      getDropdownItem('shareFile', 'files', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'files', row, refresh, utils),
    ],
    [
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'files', row, refresh, utils),
      getDropdownItem('immutable', 'files', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'files', row, refresh, utils),
      getDropdownItem('delete', 'files', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenUpload,
    slideoverOpenEdit,
    slideoverOpenList,
    schema,
    getDropdownItems,
  }
}

export const useFilesUtils = () => {
  const options = {
    ...optionsFiles,
  }

  const maps = {
    tableName: toMapByKey(optionsFiles.tableName, 'value'),
  }

  return { options, maps }
}
