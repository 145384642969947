import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId } from '#netzo/utils/core/db'
import { merge } from 'es-toolkit/compat'

export const getDefaultService = (data: Partial<Service>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    fiscalData: {},
    images: [],
    tags: [],
    data: getDefaultServiceData(data?.data ?? {}),
  }, data)
}

export const getServiceInformation = (data: Partial<ServiceWithRelations>) => {
  const maps = {
    type: toMapByKey(optionsServices.type, 'value'),
  }
  if (!data) return []
  return [
    ['Tipo', maps.type.get(data.type!)?.label],
    ['Proveedor', data.supplier?.name],
    ['Clave Producto (SAT)', data.fiscalData?.satProductServiceKey],
  ].map(([key, value]) => [key, value || '-'])
}

export const optionsServices = {
  type: [
    { value: 'core', label: 'Principales', icon: 'i-mdi-heart-pulse', color: 'blue', description: 'Servicios principales que son el núcleo del negocio que constituyen la base de la oferta.' },
    { value: 'logistics', label: 'Envío y Logística', icon: 'i-mdi-truck-delivery', color: 'teal', description: 'Servicios de logística y envío, tanto nacionales como internacionales, gestionando transporte, embalaje y entrega de productos.' },
    { value: 'specialized', label: 'Especializados', icon: 'i-mdi-hammer-wrench', color: 'gray', description: 'Servicios fuera de lo convencional, como asesoría, consultoría o servicios de emergencia, que requieren conocimientos especializados.' },
    { value: 'other', label: 'Otros', icon: 'i-mdi-dots-horizontal', color: 'gray', description: 'Servicios que no encajan en las tipos anteriores.' },
  ],
} satisfies Options

export const optionsServicesMap = {
  Tipo: optionsServices.type,
}

export const relationsServices: Record<string, boolean> = {
  category: true,
  serviceitems: true,
  supplier: true,
  files: true,
  // options: true,
}

export type ServiceWithRelations = Service & {
  category: Category
  serviceitems: Serviceitem[]
  supplier: Account
  files: File[]
  options: Option[]
}

export const viewSettingsServices: ViewSettings = {
  tableName: 'services',
  typeOptions: optionsShared.views.filter(view => ['grid', 'cards'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 50 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'image',
      label: 'Imágen',
      class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'category.name',
      label: 'Categoría',
      sortable: true,
    },
    {
      key: 'supplier.name',
      label: 'Proveedor',
      sortable: true,
    },
    {
      key: 'fiscalData.satProductServiceKey',
      label: 'Clave (SAT)',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
