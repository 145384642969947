import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId } from '#netzo/utils/core/db'
import { merge } from 'es-toolkit/compat'

export const getDefaultIssue = (data: Partial<Issue>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'enhancement',
    status: 'new',
    priority: '2',
    tags: [],
  }, data)
}

export const optionsIssues = {
  type: [
    {
      value: 'type: idea',
      label: 'Propuesta de una idea o sugerencia',
      icon: 'i-mdi-lightbulb',
      color: 'cyan',
    },
    {
      value: 'type: feature',
      label: 'Propuesta de una nueva funcionalidad',
      icon: 'i-mdi-star',
      color: 'green',
    },
    {
      value: 'type: question',
      label: 'Pregunta o duda sobre la aplicación',
      icon: 'i-mdi-help',
      color: 'fuchsia',
    },
    {
      value: 'type: enhancement',
      label: 'Mejora de una funcionalidad existente',
      icon: 'i-mdi-shimmer',
      color: 'emerald',
    },
    {
      value: 'type: bug',
      label: 'Reporte de un error o bug',
      icon: 'i-mdi-bug',
      color: 'red',
    },
  ],
  status: [
    {
      value: 'new',
      label: 'Nuevo',
      description: 'El asunto es nuevo y aún no ha sido programado.',
      icon: 'i-mdi-circle-outline',
      color: 'gray',
    },
    {
      value: 'scheduled',
      label: 'Programado',
      description: 'El asunto ha sido programado para ser atendido.',
      icon: 'i-mdi-dots-horizontal-circle-outline',
      color: 'red',
    },
    {
      value: 'in-progress',
      label: 'En progreso',
      description: 'El asunto está en proceso de ser completado.',
      icon: 'i-mdi-progress-clock',
      color: 'orange',
    },
    {
      value: 'waiting',
      label: 'Esperando',
      description: 'El asunto está esperando una respuesta o acción.',
      icon: 'i-mdi-clock',
      color: 'amber',
    },
    {
      value: 'stuck',
      label: 'Atorado',
      description: 'El asunto está atascado y necesita ser revisado.',
      icon: 'i-mdi-clock-alert',
      color: 'purple',
    },
    {
      value: 'completed',
      label: 'Completado',
      description: 'El asunto se ha completado con éxito.',
      icon: 'i-mdi-check-circle',
      color: 'green',
    },
  ],
  priority: [
    {
      value: '1',
      label: 'Baja',
      description: 'No es importante y puede esperar',
      icon: 'i-mdi-arrow-down-circle',
      color: 'green',
    },
    {
      value: '2',
      label: 'Media',
      description: 'Es importante pero puede esperar',
      icon: 'i-mdi-arrow-right-circle',
      color: 'amber',
    },
    {
      value: '3',
      label: 'Alta',
      description: 'Es importante y necesita ser atendido pronto',
      icon: 'i-mdi-arrow-up-circle',
      color: 'orange',
    },
    {
      value: '4',
      label: 'Critica',
      description: 'Es urgente, necesita ser atendido de inmediato',
      icon: 'i-mdi-alert',
      color: 'red',
    },
  ],
} satisfies Options

export const optionsIssuesMap = {
  Estado: optionsIssues.status,
  Prioridad: optionsIssues.priority,
}

export const relationsIssues: Record<string, boolean> = {
  user: true,
  files: true,
  // options: true,
}

export type IssueWithRelations = Issue & {
  user: User
  files: File[]
  options: Option[]
}

export const viewSettingsIssues: ViewSettings = {
  tableName: 'issues',
  typeOptions: optionsShared.views.filter(view => ['grid', 'kanban'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 50 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'priority',
      label: 'Prioridad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groupBy: 'status',
  groupByOptions: [
    { label: 'Estado', value: 'status', options: optionsIssues.status },
    { label: 'Prioridad', value: 'priority', options: optionsIssues.priority },
  ],
}
