import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId } from '#netzo/utils/core/db'
import { merge } from 'es-toolkit/compat'

export const getDefaultSupplier = (data: Partial<Supplier>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'prospect',
    tags: [],
    data: getDefaultSupplierData(data?.data ?? {}),
  }, data)
}

export const getSupplierInformation = (data: Partial<SupplierWithRelations>) => {
  const maps = {
    type: toMapByKey(optionsSuppliers.type, 'value'),
  }
  if (!data) return []
  return [
    ['Nombre', data?.name],
    ['Tipo', maps.type.get(data?.type ?? '')?.label],
    ['Pagina web', data?.domain],
  ].map(([key, value]) => [key, value || '-'])
}

export const optionsSuppliers = {
  type: [
    { value: 'raw-material', label: 'Materia Prima', description: 'Proveedor que suministra materiales básicos no procesados necesarios para la fabricación de productos.', color: 'green', icon: 'i-mdi-cogs' },
    { value: 'component', label: 'Componente', description: 'Proveedor que ofrece piezas o partes específicas que son ensambladas para crear un producto final.', color: 'blue', icon: 'i-mdi-puzzle' },
    { value: 'equipment', label: 'Equipo', description: 'Proveedor de maquinaria, herramientas o equipo necesario para el proceso de fabricación.', color: 'orange', icon: 'i-mdi-wrench' },
    { value: 'contract-manufacturer', label: 'Fabricante por Contrato', description: 'Proveedor que fabrica productos bajo nuestras especificaciones, sin poner su propia marca en el producto.', color: 'purple', icon: 'i-mdi-factory' },
    { value: 'distributor', label: 'Distribuidor', description: 'Proveedor que distribuye productos de varios fabricantes.', color: 'teal', icon: 'i-mdi-truck' },
    { value: 'oem', label: 'OEM (Fabricante de Equipos Originales)', description: 'Proveedor que fabrica equipos y componentes que luego serán comercializados bajo su propia marca.', color: 'red', icon: 'i-mdi-cogs' },
    { value: 'service-provider', label: 'Proveedor de Servicios', description: 'Proveedor que ofrece servicios de apoyo, como logística, transporte, mantenimiento o consultoría técnica para el proceso de manufactura.', color: 'yellow', icon: 'i-mdi-briefcase' },
    { value: 'logistics', label: 'Logística', description: 'Proveedor que maneja el transporte, almacenamiento y distribución de productos o materiales necesarios para la producción.', color: 'brown', icon: 'i-mdi-truck-fast' },
    { value: 'packaging', label: 'Empaque', description: 'Proveedor que suministra materiales o soluciones para el embalaje de productos terminados.', color: 'lightblue', icon: 'i-mdi-box' },
    { value: 'maintenance', label: 'Mantenimiento', description: 'Proveedor que se encarga del mantenimiento preventivo o correctivo de las máquinas y equipos utilizados en la producción.', color: 'gray', icon: 'i-mdi-wrench' },
  ],
} satisfies Options

export const optionsSuppliersMap = {
  Tipo: optionsSuppliers.type,
}

export const relationsSuppliers: Record<string, boolean> = {
  contacts: true,
  services: true,
  products: true,
  user: true,
  files: true,
  // options: true,
}

export type SupplierWithRelations = Supplier & {
  contacts: Contact[]
  products: Product[]
  services: Service[]
  user: User
  files: File[]
  options: Option[]
}

export const viewSettingsSuppliers: ViewSettings = {
  tableName: 'suppliers',
  typeOptions: optionsShared.views.filter(view => ['grid', 'cards'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 50 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'domain',
      label: 'Dominio',
      sortable: true,
    },
    {
      key: 'user.name',
      label: 'Responsable',
      sortable: true,
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
