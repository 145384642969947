import { AppQuoteitemsSlideover, AppQuoteitemsSlideoverItems } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppQuoteitemsSlideoverProps = ComponentProps<typeof AppQuoteitemsSlideover>
type AppQuoteitemsSlideoverItemsProps = ComponentProps<typeof AppQuoteitemsSlideoverItems>

export type QuoteitemsUtils = ReturnType<typeof useQuoteitemsUtils>

export const useQuoteitems = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Quoteitem>('quoteitems')

  const slideoverOpenCreate = (props: AppQuoteitemsSlideoverProps) => {
    return new Promise<Quoteitem>((resolve, reject) => {
      const data = getDefaultQuoteitem(props.data)
      slideover.reset()
      slideover.open(AppQuoteitemsSlideover, {
        type: 'POST',
        title: 'Agregar item',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
            slideover.reset()
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
      })
    })
  }

  const slideoverOpenEdit = (props: AppQuoteitemsSlideoverProps) => {
    return new Promise<Quoteitem>((resolve, reject) => {
      const data = getDefaultQuoteitem(props.data)
      slideover.reset()
      slideover.open(AppQuoteitemsSlideover, {
        title: 'Editar item',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
            slideover.reset()
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const slideoverOpenItems = (props: AppQuoteitemsSlideoverItemsProps) => {
    return new Promise<Quote>((resolve, reject) => {
      const data = getDefaultQuote(props.data)
      slideover.reset()
      slideover.open(AppQuoteitemsSlideoverItems, {
        title: 'Agregar items',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
            slideover.reset()
          }
        }),
      })
    })
  }

  const schemaProducts = z.object({
    type: z.string(),
    subtype: z.string(),
    name: z.string(),
    unit: z.string(),
    fiscalData: fiscalDataSchema,
    quantity: z.number(),
    unitPrice: z.number(),
    amount: z.number(),
    quoteId: z.string(),
    productitemId: z.string(),
  })

  const schemaServices = z.object({
    type: z.string(),
    subtype: z.string(),
    name: z.string(),
    unit: z.string(),
    fiscalData: fiscalDataSchema,
    quantity: z.number(),
    unitPrice: z.number(),
    amount: z.number(),
    quoteId: z.string(),
    serviceitemId: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Quoteitem, refresh: () => Promise<void>, utils: QuoteitemsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'quoteitems', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'quoteitems', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'quoteitems', row, refresh, utils),
      getDropdownItem('files', 'quoteitems', row, refresh, utils),
    ],
    [
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'quoteitems', row, refresh, utils),
      {
        label: `${row.immutable ? 'Habilitar' : 'Deshabilitar'}`,
        icon: row.immutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        disabled: row?.quote?.immutable,
        click: async () => {
          await onSubmitUpdate({ ...row, immutable: !row.immutable })
          await refresh()
        },
      },
    ],
    [
      getDropdownItem('archive', 'quoteitems', row, refresh, utils),
      getDropdownItem('delete', 'quoteitems', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    slideoverOpenEdit,
    slideoverOpenItems,
    schemaProducts,
    schemaServices,
    getDropdownItems,
  }
}

export const useQuoteitemsUtils = ({ $pricelists, $productitems, $serviceitems }: {
  $pricelists?: Awaited<ReturnType<typeof useFetch<PricelistWithRelations[]>>>
  $productitems?: Awaited<ReturnType<typeof useFetch<Productitem[]>>>
  $serviceitems?: Awaited<ReturnType<typeof useFetch<Serviceitem[]>>>
} = {}) => {
  const options = {
    ...optionsQuoteitems,
    unit: optionsShared.unit,
    pricelistId: computed(() => $pricelists?.data.value.map(toOption)),
    productitemId: computed(() => $productitems?.data.value.map(toOption)),
    serviceitemId: computed(() => $serviceitems?.data.value.map(toOption)),
  }

  const maps = {
    unit: toMapByKey(optionsShared.unit, 'value'),
    type: toMapByKey(optionsQuoteitems.type, 'value'),
    subtype: toMapByKeyFromObject(optionsQuoteitems.subtype, 'value'),
    fiscalData: {
      satUnitKey: toMapByKey(optionsSAT.productUnitId, 'value'),
      satTaxObject: toMapByKey(optionsSAT.taxObject, 'value'),
    },
    taxTypes: toMapByKey(optionsSAT.taxTypes, 'value'),
    taxFactor: toMapByKey(optionsSAT.taxFactor, 'value'),
    pricelistId: computed(() => toMapByKey($pricelists?.data.value, 'id')),
    productitemId: computed(() => toMapByKey($productitems?.data.value, 'id')),
    serviceitemId: computed(() => toMapByKey($serviceitems?.data.value, 'id')),
    pricelistsByType: computed(() => toMapByGroupKey($pricelists?.data.value, 'type')),
    productitemsByProductType: computed(() => toMapByNestedKey($productitems?.data.value, 'product', 'type')),
    serviceitemsByServiceType: computed(() => toMapByNestedKey($serviceitems?.data.value, 'service', 'type')),
  }

  return { options, maps }
}
