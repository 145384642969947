import { AppContactsSlideover } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppContactsSlideoverProps = ComponentProps<typeof AppContactsSlideover>

export type ContactsUtils = ReturnType<typeof useContactsUtils>

export const useContacts = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Contact>('contacts')

  const slideoverOpenCreate = (props: AppContactsSlideoverProps) => {
    return new Promise<Contact>((resolve, reject) => {
      const { user } = useUserSession()
      const { moduleId } = useModules()
      const data = getDefaultContact({ userId: user.value.id, moduleId: moduleId.value, ...props?.data })
      slideover.reset()
      slideover.open(AppContactsSlideover, {
        title: 'Crear contacto',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
            slideover.reset()
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
      })
    })
  }

  const slideoverOpenEdit = (props: AppContactsSlideoverProps) => {
    return new Promise<Contact>((resolve, reject) => {
      const data = getDefaultContact(props.data)
      slideover.reset()
      slideover.open(AppContactsSlideover, {
        title: 'Editar contacto',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
            slideover.reset()
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const schema = z.object({
    name: z.string(),
    userId: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Contact, refresh: () => Promise<void>, utils: ContactsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'contacts', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'contacts', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'contacts', row, refresh, utils),
      getDropdownItem('files', 'contacts', row, refresh, utils),
    ],
    [
      ...(row.moduleId === 'sales'
        ? [
            {
              label: 'Crear cuenta',
              icon: ICONS.accounts,
              disabled: !!row.accountId,
              click: () => {
                const accounts$ = useAccounts()
                accounts$.slideoverOpenCreate({
                  data: { userId: row.userId },
                  disabledFields: [],
                  onSubmit: async (event) => {
                    const account = await accounts$.onSubmitCreate(event.data)
                    await onSubmitUpdate({ ...row, accountId: account!.id })
                    accounts$.slideover.close()
                  },
                }).then(refresh)
              },
            },
            {
              label: 'Agregar sucursal',
              icon: ICONS.branches,
              disabled: !row.accountId || (!!row.accountId && !!row.branchId),
              click: () => {
                const branches$ = useBranches()
                branches$.slideoverOpenCreate({
                  data: { accountId: row.accountId },
                  disabledFields: ['accountId'],
                  onSubmit: async (event) => {
                    const branch = await branches$.onSubmitCreate(event.data)
                    await onSubmitUpdate({ ...row, branchId: branch!.id })
                    branches$.slideover.close()
                  },
                }).then(refresh)
              },
            },
            {
              label: 'Crear negocio',
              icon: ICONS.deals,
              disabled: !row.accountId,
              click: () => {
                const deals$ = useDeals()
                deals$.slideoverOpenCreate({
                  data: {
                    type: 'outbound',
                    accountId: row.accountId ?? undefined,
                    branchId: row.branchId ?? undefined,
                    contactIds: [row.id],
                    userId: row.userId ?? undefined,
                  },
                  disabledFields: ['type', 'accountId', row.branchId ? 'branchId' : ''],
                }).then(refresh)
              },
            },
          ]
        : []),
      ...dropdownItemsAction,
    ],
    [
      {
        label: 'Llamar',
        icon: 'i-mdi-phone-outgoing',
        disabled: !row.phone,
        click: () => open(`tel:${toPhoneLinkString(row.phone)}`),
      },
      {
        label: 'Enviar correo',
        icon: 'i-mdi-email-arrow-right',
        disabled: !row.email,
        click: () => open(`mailto:${row.email}`),
      },
    ],
    [
      getDropdownItem('info', 'contacts', row, refresh, utils),
      getDropdownItem('immutable', 'contacts', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'contacts', row, refresh, utils),
      getDropdownItem('delete', 'contacts', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useContactsUtils = ({ $accounts, $branches, $users }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $branches?: Awaited<ReturnType<typeof useFetch<Branch[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsContacts,
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    branchId: computed(() => $branches?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
  }

  const maps = {
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
    branchId: computed(() => toMapByKey($branches?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
    branchesByAccountId: computed(() => toMapByGroupKey($branches?.data.value, 'accountId')),
  }

  return { options, maps }
}
