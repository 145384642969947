import { ToWords } from 'to-words'

export const toFixed = (value = 0, digits = 0) =>
  Intl.NumberFormat('es-ES', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(value)

export const toCurrency = (value = 0, currency: 'MXN' | 'USD' = 'USD') => {
  const locales = { MXN: 'es-MX', USD: 'en-US', EUR: 'es-ES' }
  return new Intl.NumberFormat(locales[currency], {
    style: 'currency',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    currency,
  }).format(value)
}

export const toTitleCase = (string: string) => {
  return string
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const toSpelledCurrency = (currency: 'USD' | 'MXN' = 'USD') => {
  let currencyName: string
  let currencySymbol: string
  let fractionalUnitName: string
  let fractionalUnitSymbol: string

  if (currency === 'USD') {
    currencyName = 'Dólar'
    currencySymbol = '$'
    fractionalUnitName = 'Centavos'
    fractionalUnitSymbol = 'c'
  }
  else if (currency === 'MXN') {
    currencyName = 'Peso'
    currencySymbol = '$'
    fractionalUnitName = 'Centavos'
    fractionalUnitSymbol = '¢'
  }
  else {
    throw new Error('Unsupported currency')
  }

  return new ToWords({
    localeCode: 'es-ES',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        name: currencyName,
        plural: `${currencyName}${currency === 'USD' ? 'es' : 's'}`,
        symbol: currencySymbol,
        fractionalUnit: {
          name: fractionalUnitName,
          plural: `${fractionalUnitName}`,
          symbol: fractionalUnitSymbol,
        },
      },
    },
  })
}

export const toPercent = (value = 0) =>
  new Intl.NumberFormat('es-ES', {
    style: 'percent',
    minimumFractionDigits: 2,
  }).format(value)

export const toPhoneString = (phone: Phone | null | undefined) => {
  const { code, number, ext } = phone ?? {}
  if (!number) return ''
  return `${code ? `+${code} ` : ''}${number}`
}

export const toPhoneLinkString = (phone: Phone | null | undefined) => {
  const { code, number, ext } = phone ?? {}
  if (!number) return ''
  return `${code ? `+${code} ` : ''}${number}${ext ? `;ext=${ext}` : ''}`
}

export const toAddressString = (address: Address) => {
  return [
    [
      address?.street,
      address?.numberExterior,
      address?.numberInterior,
    ].filter(Boolean).join(', '),
    address?.neighborhood,
    [
      address?.postalCode ? `${address?.postalCode}` : '',
      address?.city,
      address?.district,
    ].filter(Boolean).join(', '),
    [
      address?.state,
      address?.country,
    ].filter(Boolean).join(', '),
  ].filter(Boolean).join(', ').toUpperCase().trim()
}

export const toUrl = (path: `/${string}`) => {
  return new URL(path, window.location.origin)
}

export const toFileSize = (bytes: number, mode: 'decimal' | 'binary' = 'decimal') => {
  const FACTOR = mode === 'decimal' ? 1000 : 1024
  const sizes = mode === 'decimal'
    ? ['Bytes', 'KB', 'MB', 'GB', 'TB'] // commonly used in storage and by OEMs an OSes
    : ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB'] // commonly used in computing and data transfer
  if (bytes === 0) return '0 Byte'
  const i = Math.floor(Math.log(bytes) / Math.log(FACTOR))
  return `${(bytes / Math.pow(FACTOR, i)).toFixed(2)} ${sizes[i]}`
}

export const toNumber = (value: any): number => {
  const num = Number(value)
  return isNaN(num) ? 0 : num // If it's NaN, return 0
}

export const toCurrencyAbbreviated = (value = 0, currency?: 'MXN' | 'USD'): string => {
  // Dynamically assign the default currency if not provided
  currency = currency || 'MXN'

  value = Number(value)

  if (isNaN(value)) {
    console.error('Invalid value:', value)
    throw new Error('Invalid value provided for currency formatting')
  }

  if (value < 1000) {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(toNumber(value))
  }

  const suffixes = ['K', 'M', 'B', 'T']
  let suffixIndex = -1

  while (value >= 1000) {
    value /= 1000
    suffixIndex++
  }
  const roundedValue = value.toFixed(2)

  return `${new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(parseFloat(roundedValue))}${suffixes[suffixIndex]}`
}
